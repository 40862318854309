const MyProfile = () => {
   
  
 
      
    return(
    <>
     <div className="ui bottom attached tab segment active my-profile-mpc" data-tab="myProfile">
        <ul className="pass-elements">
          <li>
            <h3>COMING SOON....</h3>
          </li>

        </ul>
        
      </div>
    
    
    </>
    )

}
export default MyProfile;