import "./ProfilePage.css";
import React, { useState, useEffect } from 'react';
import ChangeDisplayName from "./ChangeDisplayName";
import DeleteAccount from "./DeleteAccount";
import ChangePhoneNumber from "./ChangePhoneNumber";
import MyProfile from "./MyProfile";  // Importing MyProfile component
import ChangePassword from "./ChangePassword";
import Notification from "./Notification";
import Cookies from 'universal-cookie';

const UserProfileMainComponent = () => {
  const cookieNameCurrentUser = "currentAPIMUser";
  
  const [user, setUser] = useState('');
  const [page, setPage] = useState('changePassword'); // Set default active page as 'Change Password'
  
  useEffect(() => {
    const cookies = new Cookies();
    if (cookies !== null) {
      let _user_attributes = cookies.get(cookieNameCurrentUser);
      if (_user_attributes) {
        _user_attributes = decodeURI(_user_attributes);
        const user_attributes = JSON.parse(_user_attributes);
        setUser(user_attributes);
      }
    }
  }, []);
  
  return (
    <div className="container-fluid mt-3 ps-3">
      <div className="container row">
        <div className="col-4">
          <div className="mp-user-info-w">
            <span className="user-aplha" id="userInitial">
              {user.displayName && user.displayName.slice(0, 1)}
            </span>
          </div>
        </div>
        <div className="col-8">
          <ul className="mp-user-info">
            <li id="userName">{user.displayName}</li>
            <li id="userEmailId">{user.email}</li>
            <li id="userPhoneNo">{user.mobileNumber}</li>
          </ul>
        </div>
      </div>

      <div className="container row">
        <div className="col-4">
          <div className="list-group" id="list-tab" role="tablist">
            {/* My Profile tab */}
            <a
              className={`list-group-item list-group-item-action item p-2 ${page === 'myProfile' ? 'active' : ''}`}
              id="list-profile-list"
              onClick={() => setPage('myProfile')}
              role="tab"
              aria-controls="list-profile"
            >
              My Profile
            </a>
            {/* Change Password tab */}
            <a
              className={`list-group-item list-group-item-action item p-2 ${page === 'changePassword' ? 'active' : ''}`}
              id="list-profile-list"
              onClick={() => setPage('changePassword')}
              role="tab"
            >
              Change Password
            </a>
            {/* Change Phone Number tab */}
            <a
              className={`list-group-item list-group-item-action item p-2 ${page === 'changePhoneNumber' ? 'active' : ''}`}
              id="list-messages-list"
              onClick={() => setPage('changePhoneNumber')}
              role="tab"
            >
              Change Phone Number
            </a>
            {/* Change Display Name tab */}
            <a
              className={`list-group-item list-group-item-action item p-2 ${page === 'changeDisplayName' ? 'active' : ''}`}
              id="list-settings-list"
              onClick={() => setPage('changeDisplayName')}
              role="tab"
            >
              Change Display Name
            </a>
            {/* Notifications tab */}
            <a
              className={`list-group-item list-group-item-action item p-2 ${page === 'notifications' ? 'active' : ''}`}
              id="list-messages-list"
              onClick={() => setPage('notifications')}
              role="tab"
            >
              Notifications
            </a>
            {/* Delete My Account tab */}
            <a
              className={`list-group-item list-group-item-action item p-2 ${page === 'deleteAccount' ? 'active' : ''}`}
              id="list-settings-list"
              onClick={() => setPage('deleteAccount')}
              role="tab"
            >
              Delete My Account
            </a>
          </div>
        </div>

        <div className="col-8">
          <div className="tab-content" id="nav-tabContent">
            {/* My Profile Content */}
            <div
              className={`tab-pane fade ${page === 'myProfile' ? 'show active' : ''}`}
              id="myProfile"
              role="tabpanel"
              aria-labelledby="list-profile-list"
            >
              <MyProfile user={user} />
            </div>
            {/* Change Password Content */}
            <div
              className={`tab-pane fade ${page === 'changePassword' ? 'show active' : ''}`}
              id="changePassword"
              role="tabpanel"
            >
              <ChangePassword />
            </div>
            {/* Change Phone Number Content */}
            <div
              className={`tab-pane fade ${page === 'changePhoneNumber' ? 'show active' : ''}`}
              id="changePhoneNumber"
              role="tabpanel"
            >
              <ChangePhoneNumber />
            </div>
            {/* Change Display Name Content */}
            <div
              className={`tab-pane fade ${page === 'changeDisplayName' ? 'show active' : ''}`}
              id="changeDisplayName"
              role="tabpanel"
            >
              <ChangeDisplayName />
            </div>
            {/* Notifications Content */}
            <div
              className={`tab-pane fade ${page === 'notifications' ? 'show active' : ''}`}
              id="notifications"
              role="tabpanel"
            >
              {page === 'notifications' ? <Notification /> : <></>}
            </div>
            {/* Delete My Account Content */}
            <div
              className={`tab-pane fade ${page === 'deleteAccount' ? 'show active' : ''}`}
              id="deleteMyAccount"
              role="tabpanel"
            >
              <DeleteAccount userEmail={user.email} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfileMainComponent;
