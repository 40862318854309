
import React, { useState, useEffect } from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useNavigate } from 'react-router-dom';
import {Popover} from 'react-bootstrap'
import Form from 'react-bootstrap/Form';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import OtpInputModal from '../Company/OtpInputModal';
import SRQService from '../../Services/SRQService';
import ProjectsService from '../../Services/ProjectsService';
import ServiceRequestModel from './ServiceRequestModel';
import { ToastContainer, toast ,Slide} from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import 'react-toastify/dist/ReactToastify.css';

// import './ServiceRequest.css';
import './CreateCompany.css';

const CreateCompany = () => {
  const navigate = useNavigate();
  const pageReload = () => {
    // 👇️ navigate to /
    window.location.reload();
  };
  const parseLines = (value) => value.replace(/(\\n)/g, "\n");
  
  const [isCreateSRQButtonActive, setIsCreateSRQButtonActive] = useState(false)
  const [flagSelected, setFlagSelected] = useState(false);
  
  //company
  const [newCompanyName, setNewCompanyName] = useState('')
  const [newCompanyCountryCode, setNewCompanyCountryCode] = useState('')
  const [newCompanyRegistrationCode, setNewCompanyRegistrationCode] = useState('')
  const [newCompanyDescription, setNewCompanyDescription] = useState('')
  //representative
  const [representativeDisplayName, setRepresentativeDisplayName] = useState('')
  const [representativeEmailId, setRepresentativeEmailId] = useState('')
  const [representativePhoneNumber, setRepresentativePhoneNumber] = useState('')
  const [representativeCountryCode, setRepresentativeCountryCode] = useState('')
  const [representativeCountry, setRepresentativeCountry] = useState({})
  const [validRepPhoneNumber, setValidRepPhoneNumber] = useState(true);
  //AdditionalDetails
  const [additionalDetails, setAdditionalDetails] = useState('')
  //otpinput
  const [OtpModalOpen, setOtpModalOpen] = useState(false);
  const[OtpDataFromModal,setOtpDataFromModal] = useState([]);
  //Confirmation modal
  const [modalShow, setModalShow] = React.useState(false);
  var otpInputModelOpenendForAction = '';

  console.log("countrycode:"+representativeCountryCode);
  console.log("phonenumber:"+representativePhoneNumber);
  console.log("country:"+newCompanyCountryCode);
  console.log(representativeCountry.dialCode);

  const handleSelectCountryCode = (event) => {
    let eValue=event.target.value;
    setNewCompanyCountryCode(eValue);
  }

 
  //confirmation modal view
  function MyVerticallyCenteredModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        animation={false}
        backdrop='static' 
        keyboard="False"
        dialogClassName='my-modal'
       


      >
        <Modal.Header className='modal-header'>
          <Modal.Title id="contained-modal-title-vcenter">
          Confirmation on  service request
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='modal-body'>
          <p>
          Request accepted for CreateCompany, request number shall be updated soon!</p>
        </Modal.Body>
        <Modal.Footer>
        <button className="ui button comm-button-style primary" onClick={onServiceRequestCreated}>OK</button>
        </Modal.Footer>
      </Modal>
    );
  }
  function clearAllFields()
  {
    setNewCompanyName('');
  setNewCompanyCountryCode('')
  setNewCompanyRegistrationCode('')
   setNewCompanyDescription('')
 setRepresentativeDisplayName('')
 setRepresentativeEmailId('')
   setRepresentativePhoneNumber('')
  setRepresentativeCountryCode('')
   setRepresentativeCountry({})
   setAdditionalDetails('')
  }
  const onServiceRequestCreated =  ()  =>{
    console.log("inside onServiceRequestCreated function")
    clearAllFields();
    setModalShow(false);
    window.location.href="/viewserviceRequests";
    
    
   
  };

  function createCompanyDefaultRequestBuilder(){
    
   
  };
  
  const handleOtp = (OtpDataToProject) => {
    setOtpDataFromModal(OtpDataToProject);
  }
  async function verifyOtp(OtpDataFromModal){
  
    if(OtpDataFromModal.length!==0){
    
      console.log(OtpDataFromModal)
      OtpDataFromModal  = Number(OtpDataFromModal.join(''));
      //alert("otp:"+OtpDataFromModal)
      // ServiceRequestModel.companyId=null;
      // ServiceRequestModel.projectId=null;
      ServiceRequestModel.requestType="CreateCompany";
      ServiceRequestModel.requestText=additionalDetails.replace(/(?:\r\n|\r|\n)/g, ' ');
      ServiceRequestModel.subjectNewUser.displayName =representativeDisplayName;
      ServiceRequestModel.subjectNewUser.email=representativeEmailId;
      ServiceRequestModel.subjectNewUser.mobile="+"+representativePhoneNumber;
      ServiceRequestModel.subjectCompany.name=newCompanyName;
      ServiceRequestModel.subjectCompany.countryCode=newCompanyCountryCode;
      ServiceRequestModel.subjectCompany.registrationCode=newCompanyRegistrationCode;
      ServiceRequestModel.subjectCompany.description=newCompanyDescription.replace(/(?:\r\n|\r|\n)/g, ' ');
      console.log(ServiceRequestModel);
       
     // alert("userDetailsObject.password"+userDetailsObject.password);
     try {
      //alert("verifyOtpforCreateCtedential"+OtpDataFromModal);
      // let temp= createCompanyDefaultRequestBuilder();
      // alert(temp.);
         const response = await SRQService.createNewServiceRequest(ServiceRequestModel,OtpDataFromModal);
         if(response)
         {
          setOtpModalOpen(false);
          setModalShow(true);
         }
              } catch (error) {
                setOtpModalOpen(true);
                toast.error(error.response.data.message, {
                  position: toast.POSITION.BOTTOM_RIGHT,
                  autoClose: 8000, //6 seconds
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  transition: Slide
                });
          }
      
      
    
     }
    
      }
  const popover = (data) =>(  
  
    <Popover id="popover-basic" style={{maxWidth: '700px',height:'90px',borderColor:'black',borderStyle:'solid',pointerEvents:'none'}}>    
      <Popover.Body className='row'>  
        <p  className='row'> {data}</p>
      </Popover.Body>  
    </Popover>  
  );

  async function handleCreateSRQButton() {

  }
  //when create button is clicked
  async function handleSubmit() {
    console.log("HI FROM Creeate company")
    //e.preventDefault();
    setIsCreateSRQButtonActive(true);
    //userDetailsObject.password=password;
   
    
    otpInputModelOpenendForAction = "registerCompany";
    setOtpModalOpen(true);
    
    // 
    try {
        const response = await ProjectsService.getStrongConfirmationCode("POST",null,"registerCompany",null,null,null);
    if (response && response.data.channel == "SMS") {
        otpInputModelOpenendForAction = "registerCompany";
        
        
        setOtpModalOpen(true);
       // alert("otp entered from otp modal for deleting credentials is ---->" + OtpDataFromModal);
      }
   
        
    } catch (error) {
        console.log(error);
    }
   // setModalShow(true);  //enable here for modal testing
   
  }

  const resendOtp = async() => {
    await ProjectsService.getStrongConfirmationCode("POST",null,"registerCompany",null,null,null);
  }

    return(
    <>
           <li>
    <div className="sec">
      <div className="head-titlw-w head-titl-unset" style={{maxWidth: '50%'}}>
        <h3
          className="sec-title"
          id="CreateCompany-Default-promptForNewCompany"
          data-info=""
        >
          Company Details
        </h3>
         <OverlayTrigger   popperConfig={{
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }}placement="top"  delay={{ show: 250, hide: 400 }} overlay={popover("Please provide the basic information about your company.")}>
                                <a className="info-icon-w pop-common-sel sr-pophover" id="infoSelectedServiceRequestType"style={{visibility: 'visible'}} target="_blank">
                                  <span className="bi bi-info-circle mt-2" /> 
                                </a>
    </OverlayTrigger>
      </div>
      <ul className="serv-req sec-inner-wrap">
        
        <li>
          <div className="mt-3">
            <div className="custom-input-wrapp">
            <FloatingLabel
        controlId="ncInputCompanyName"
        label="Company name"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setNewCompanyName(event.target.value)}
      >
        <Form.Control type="text" placeholder="name@example.com" />
        <span className="input-info-label">Eg: Nets</span>
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Company name is invalid</span>
      </FloatingLabel>
             
            </div>
          </div>
        </li>
        <li className="select-drop-message">
          <div className="contact-num-wrap mt-3 row">
            <div className="country-code-w col-5">
            <Form.Select
                      defaultValue="Select Country Code"
                      value={newCompanyCountryCode}
                      //styles={customstyles}
                      style={{ width: "100%" }}
                      onChange={handleSelectCountryCode}
                      
                    >
                      <option selected="selected">Select Country Code</option>
                      <option className="textdefault" value="US">
                        USA (US)
                      </option>
                      <option className="textdefault" value="GB">
                        UK (GB)
                      </option>
                      <option className="textdefault" value="DZ">
                        Algeria (DZ)
                      </option>
                      <option className="textdefault" value="AD">
                        Andorra (AD)
                      </option>
                      <option className="textdefault" value="AO">
                        Angola (AO)
                      </option>
                      <option className="textdefault" value="AI">
                        Anguilla (AI)
                      </option>
                      <option className="textdefault" value="AG">
                        Antigua &amp; Barbuda (AG)
                      </option>
                      <option className="textdefault" value="AR">
                        Argentina (AR)
                      </option>
                      <option className="textdefault" value="AM">
                        Armenia (AM)
                      </option>
                      <option className="textdefault" value="AW">
                        Aruba (AW)
                      </option>
                      <option className="textdefault" value="AU">
                        Australia (AU)
                      </option>
                      <option className="textdefault" value="AT">
                        Austria (AT)
                      </option>
                      <option className="textdefault" value="AZ">
                        Azerbaijan (AZ)
                      </option>
                      <option className="textdefault" value="BS">
                        Bahamas (BS)
                      </option>
                      <option className="textdefault" value="BH">
                        Bahrain (BH)
                      </option>
                      <option className="textdefault" value="BD">
                        Bangladesh (BD)
                      </option>
                      <option className="textdefault" value="BB">
                        Barbados (BB)
                      </option>
                      <option className="textdefault" value="BY">
                        Belarus (BY)
                      </option>
                      <option className="textdefault" value="BE">
                        Belgium (BE)
                      </option>
                      <option className="textdefault" value="BZ">
                        Belize (BZ)
                      </option>
                      <option className="textdefault" value="BJ">
                        Benin (BJ)
                      </option>
                      <option className="textdefault" value="BM">
                        Bermuda (BM)
                      </option>
                      <option className="textdefault" value="BT">
                        Bhutan (BT)
                      </option>
                      <option className="textdefault" value="BO">
                        Bolivia (BO)
                      </option>
                      <option className="textdefault" value="BA">
                        Bosnia Herzegovina (BA)
                      </option>
                      <option className="textdefault" value="BW">
                        Botswana (BW)
                      </option>
                      <option className="textdefault" value="BR">
                        Brazil (BR)
                      </option>
                      <option className="textdefault" value="BN">
                        Brunei (BN)
                      </option>
                      <option className="textdefault" value="BG">
                        Bulgaria (BG)
                      </option>
                      <option className="textdefault" value="BF">
                        Burkina Faso (BF)
                      </option>
                      <option className="textdefault" value="BI">
                        Burundi (BI)
                      </option>
                      <option className="textdefault" value="KH">
                        Cambodia (KH)
                      </option>
                      <option className="textdefault" value="CM">
                        Cameroon (CM)
                      </option>
                      <option className="textdefault" value="CA">
                        Canada (CA)
                      </option>
                      <option className="textdefault" value="CV">
                        Cape Verde Islands (CV)
                      </option>
                      <option className="textdefault" value="KY">
                        Cayman Islands (KY)
                      </option>
                      <option className="textdefault" value="CF">
                        Central African Republic (CF)
                      </option>
                      <option className="textdefault" value="CL">
                        Chile (CL)
                      </option>
                      <option className="textdefault" value="CN">
                        China (CN)
                      </option>
                      <option className="textdefault" value="CO">
                        Colombia (CO)
                      </option>
                      <option className="textdefault" value="KM">
                        Comoros (KM)
                      </option>
                      <option className="textdefault" value="CG">
                        Congo (CG)
                      </option>
                      <option className="textdefault" value="CK">
                        Cook Islands (CK)
                      </option>
                      <option className="textdefault" value="CR">
                        Costa Rica (CR)
                      </option>
                      <option className="textdefault" value="HR">
                        Croatia (HR)
                      </option>
                      <option className="textdefault" value="CU">
                        Cuba (CU)
                      </option>
                      <option className="textdefault" value="CY">
                        Cyprus - North (CY)
                      </option>
                      <option className="textdefault" value="CY">
                        Cyprus - South (CY)
                      </option>
                      <option className="textdefault" value="CZ">
                        Czech Republic (CZ)
                      </option>
                      <option className="textdefault" value="DK">
                        Denmark (DK)
                      </option>
                      <option className="textdefault" value="DJ">
                        Djibouti (DJ)
                      </option>
                      <option className="textdefault" value="DM">
                        Dominica (DM)
                      </option>
                      <option className="textdefault" value="DO">
                        Dominican Republic (DO)
                      </option>
                      <option className="textdefault" value="EC">
                        Ecuador (EC)
                      </option>
                      <option className="textdefault" value="EG">
                        Egypt (EG)
                      </option>
                      <option className="textdefault" value="SV">
                        El Salvador (SV)
                      </option>
                      <option className="textdefault" value="GQ">
                        Equatorial Guinea (GQ)
                      </option>
                      <option className="textdefault" value="ER">
                        Eritrea (ER)
                      </option>
                      <option className="textdefault" value="EE">
                        Estonia (EE)
                      </option>
                      <option className="textdefault" value="ET">
                        Ethiopia (ET)
                      </option>
                      <option className="textdefault" value="FK">
                        Falkland Islands (FK)
                      </option>
                      <option className="textdefault" value="FO">
                        Faroe Islands (FO)
                      </option>
                      <option className="textdefault" value="FJ">
                        Fiji (FJ)
                      </option>
                      <option className="textdefault" value="FI">
                        Finland (FI)
                      </option>
                      <option className="textdefault" value="FR">
                        France (FR)
                      </option>
                      <option className="textdefault" value="GF">
                        French Guiana (GF)
                      </option>
                      <option className="textdefault" value="PF">
                        French Polynesia (PF)
                      </option>
                      <option className="textdefault" value="GA">
                        Gabon (GA)
                      </option>
                      <option className="textdefault" value="GM">
                        Gambia (GM)
                      </option>
                      <option className="textdefault" value="GE">
                        Georgia (GE)
                      </option>
                      <option className="textdefault" value="DE">
                        Germany (DE)
                      </option>
                      <option className="textdefault" value="GH">
                        Ghana (GH)
                      </option>
                      <option className="textdefault" value="GI">
                        Gibraltar (GI)
                      </option>
                      <option className="textdefault" value="GR">
                        Greece (GR)
                      </option>
                      <option className="textdefault" value="GL">
                        Greenland (GL)
                      </option>
                      <option className="textdefault" value="GD">
                        Grenada (GD)
                      </option>
                      <option className="textdefault" value="GP">
                        Guadeloupe (GP)
                      </option>
                      <option className="textdefault" value="GU">
                        Guam (GU)
                      </option>
                      <option className="textdefault" value="GT">
                        Guatemala (GT)
                      </option>
                      <option className="textdefault" value="GN">
                        Guinea (GN)
                      </option>
                      <option className="textdefault" value="GW">
                        Guinea - Bissau (GW)
                      </option>
                      <option className="textdefault" value="GY">
                        Guyana (GY)
                      </option>
                      <option className="textdefault" value="HT">
                        Haiti (HT)
                      </option>
                      <option className="textdefault" value="HN">
                        Honduras (HN)
                      </option>
                      <option className="textdefault" value="HK">
                        Hong Kong (HK)
                      </option>
                      <option className="textdefault" value="HU">
                        Hungary (HU)
                      </option>
                      <option className="textdefault" value="IS">
                        Iceland (IS)
                      </option>
                      <option className="textdefault" value="IN">
                        India (IN)
                      </option>
                      <option className="textdefault" value="ID">
                        Indonesia (ID)
                      </option>
                      <option className="textdefault" value="IQ">
                        Iraq (IQ)
                      </option>
                      <option className="textdefault" value="IR">
                        Iran (IR)
                      </option>
                      <option className="textdefault" value="IE">
                        Ireland (IE)
                      </option>
                      <option className="textdefault" value="IL">
                        Israel (IL)
                      </option>
                      <option className="textdefault" value="IT">
                        Italy (IT)
                      </option>
                      <option className="textdefault" value="JM">
                        Jamaica (JM)
                      </option>
                      <option className="textdefault" value="JP">
                        Japan (JP)
                      </option>
                      <option className="textdefault" value="JO">
                        Jordan (JO)
                      </option>
                      <option className="textdefault" value="KZ">
                        Kazakhstan (KZ)
                      </option>
                      <option className="textdefault" value="KE">
                        Kenya (KE)
                      </option>
                      <option className="textdefault" value="KI">
                        Kiribati (KI)
                      </option>
                      <option className="textdefault" value="KP">
                        Korea - North (KP)
                      </option>
                      <option className="textdefault" value="KR">
                        Korea - South (KR)
                      </option>
                      <option className="textdefault" value="KW">
                        Kuwait (KW)
                      </option>
                      <option className="textdefault" value="KG">
                        Kyrgyzstan (KG)
                      </option>
                      <option className="textdefault" value="LA">
                        Laos (LA)
                      </option>
                      <option className="textdefault" value="LV">
                        Latvia (LV)
                      </option>
                      <option className="textdefault" value="LB">
                        Lebanon (LB)
                      </option>
                      <option className="textdefault" value="LS">
                        Lesotho (LS)
                      </option>
                      <option className="textdefault" value="LR">
                        Liberia (LR)
                      </option>
                      <option className="textdefault" value="LY">
                        Libya (LY)
                      </option>
                      <option className="textdefault" value="LI">
                        Liechtenstein (LI)
                      </option>
                      <option className="textdefault" value="LT">
                        Lithuania (LT)
                      </option>
                      <option className="textdefault" value="LU">
                        Luxembourg (LU)
                      </option>
                      <option className="textdefault" value="MO">
                        Macao (MO)
                      </option>
                      <option className="textdefault" value="MK">
                        Macedonia (MK)
                      </option>
                      <option className="textdefault" value="MG">
                        Madagascar (MG)
                      </option>
                      <option className="textdefault" value="MW">
                        Malawi (MW)
                      </option>
                      <option className="textdefault" value="MY">
                        Malaysia (MY)
                      </option>
                      <option className="textdefault" value="MV">
                        Maldives (MV)
                      </option>
                      <option className="textdefault" value="ML">
                        Mali (ML)
                      </option>
                      <option className="textdefault" value="MT">
                        Malta (MT)
                      </option>
                      <option className="textdefault" value="MH">
                        Marshall Islands (MH)
                      </option>
                      <option className="textdefault" value="MQ">
                        Martinique (MQ)
                      </option>
                      <option className="textdefault" value="MR">
                        Mauritania (MR)
                      </option>
                      <option className="textdefault" value="YT">
                        Mayotte (YT)
                      </option>
                      <option className="textdefault" value="MX">
                        Mexico (MX)
                      </option>
                      <option className="textdefault" value="FM">
                        Micronesia (FM)
                      </option>
                      <option className="textdefault" value="MD">
                        Moldova (MD)
                      </option>
                      <option className="textdefault" value="MC">
                        Monaco (MC)
                      </option>
                      <option className="textdefault" value="MN">
                        Mongolia (MN)
                      </option>
                      <option className="textdefault" value="MS">
                        Montserrat (MS)
                      </option>
                      <option className="textdefault" value="MA">
                        Morocco (MA)
                      </option>
                      <option className="textdefault" value="MZ">
                        Mozambique (MZ)
                      </option>
                      <option className="textdefault" value="MN">
                        Myanmar (MN)
                      </option>
                      <option className="textdefault" value="NA">
                        Namibia (NA)
                      </option>
                      <option className="textdefault" value="NR">
                        Nauru (NR)
                      </option>
                      <option className="textdefault" value="NP">
                        Nepal (NP)
                      </option>
                      <option className="textdefault" value="NL">
                        Netherlands (NL)
                      </option>
                      <option className="textdefault" value="NC">
                        New Caledonia (NC)
                      </option>
                      <option className="textdefault" value="NZ">
                        New Zealand (NZ)
                      </option>
                      <option className="textdefault" value="NI">
                        Nicaragua (NI)
                      </option>
                      <option className="textdefault" value="NE">
                        Niger (NE)
                      </option>
                      <option className="textdefault" value="NG">
                        Nigeria (NG)
                      </option>
                      <option className="textdefault" value="NU">
                        Niue (NU)
                      </option>
                      <option className="textdefault" value="NF">
                        Norfolk Islands (NF)
                      </option>
                      <option className="textdefault" value="NP">
                        Northern Marianas (NP)
                      </option>
                      <option className="textdefault" value="NO">
                        Norway (NO)
                      </option>
                      <option className="textdefault" value="OM">
                        Oman (OM)
                      </option>
                      <option className="textdefault" value="PK">
                        Pakistan (PK)
                      </option>
                      <option className="textdefault" value="PW">
                        Palau (PW)
                      </option>
                      <option className="textdefault" value="PA">
                        Panama (PA)
                      </option>
                      <option className="textdefault" value="PG">
                        Papua New Guinea (PG)
                      </option>
                      <option className="textdefault" value="PY">
                        Paraguay (PY)
                      </option>
                      <option className="textdefault" value="PE">
                        Peru (PE)
                      </option>
                      <option className="textdefault" value="PH">
                        Philippines (PH)
                      </option>
                      <option className="textdefault" value="PL">
                        Poland (PL)
                      </option>
                      <option className="textdefault" value="PT">
                        Portugal (PT)
                      </option>
                      <option className="textdefault" value="PR">
                        Puerto Rico (PR)
                      </option>
                      <option className="textdefault" value="QA">
                        Qatar (QA)
                      </option>
                      <option className="textdefault" value="RE">
                        Reunion (RE)
                      </option>
                      <option className="textdefault" value="RO">
                        Romania (RO)
                      </option>
                      <option className="textdefault" value="RU">
                        Russia (RU)
                      </option>
                      <option className="textdefault" value="RW">
                        Rwanda (RW)
                      </option>
                      <option className="textdefault" value="SM">
                        San Marino (SM)
                      </option>
                      <option className="textdefault" value="ST">
                        Sao Tome &amp; Principe (ST)
                      </option>
                      <option className="textdefault" value="SA">
                        Saudi Arabia (SA)
                      </option>
                      <option className="textdefault" value="SN">
                        Senegal (SN)
                      </option>
                      <option className="textdefault" value="CS">
                        Serbia (CS)
                      </option>
                      <option className="textdefault" value="SC">
                        Seychelles (SC)
                      </option>
                      <option className="textdefault" value="SL">
                        Sierra Leone (SL)
                      </option>
                      <option className="textdefault" value="SG">
                        Singapore (SG)
                      </option>
                      <option className="textdefault" value="SK">
                        Slovak Republic (SK)
                      </option>
                      <option className="textdefault" value="SI">
                        Slovenia (SI)
                      </option>
                      <option className="textdefault" value="SB">
                        Solomon Islands (SB)
                      </option>
                      <option className="textdefault" value="SO">
                        Somalia (SO)
                      </option>
                      <option className="textdefault" value="ZA">
                        South Africa (ZA)
                      </option>
                      <option className="textdefault" value="ES">
                        Spain (ES)
                      </option>
                      <option className="textdefault" value="LK">
                        Sri Lanka (LK)
                      </option>
                      <option className="textdefault" value="SH">
                        St. Helena (SH)
                      </option>
                      <option className="textdefault" value="KN">
                        St. Kitts (KN)
                      </option>
                      <option className="textdefault" value="SC">
                        St. Lucia (SC)
                      </option>
                      <option className="textdefault" value="SR">
                        Suriname (SR)
                      </option>
                      <option className="textdefault" value="SD">
                        Sudan (SD)
                      </option>
                      <option className="textdefault" value="SZ">
                        Swaziland (SZ)
                      </option>
                      <option className="textdefault" value="SE">
                        Sweden (SE)
                      </option>
                      <option className="textdefault" value="CH">
                        Switzerland (CH)
                      </option>
                      <option className="textdefault" value="SY">
                        Syria (SY)
                      </option>
                      <option className="textdefault" value="TW">
                        Taiwan (TW)
                      </option>
                      <option className="textdefault" value="TJ">
                        Tajikistan (TJ)
                      </option>
                      <option className="textdefault" value="TH">
                        Thailand (TH)
                      </option>
                      <option className="textdefault" value="TG">
                        Togo (TG)
                      </option>
                      <option className="textdefault" value="TO">
                        Tonga (TO)
                      </option>
                      <option className="textdefault" value="TT">
                        Trinidad &amp; Tobago (TT)
                      </option>
                      <option className="textdefault" value="TN">
                        Tunisia (TN)
                      </option>
                      <option className="textdefault" value="TR">
                        Turkey (TR)
                      </option>
                      <option className="textdefault" value="TM">
                        Turkmenistan (TM)
                      </option>
                      <option className="textdefault" value="TC">
                        Turks &amp; Caicos Islands (TC)
                      </option>
                      <option className="textdefault" value="TV">
                        Tuvalu (TV)
                      </option>
                      <option className="textdefault" value="UG">
                        Uganda (UG)
                      </option>
                      <option className="textdefault" value="UA">
                        Ukraine (UA)
                      </option>
                      <option className="textdefault" value="AE">
                        United Arab Emirates (AE)
                      </option>
                      <option className="textdefault" value="UY">
                        Uruguay (UY)
                      </option>
                      <option className="textdefault" value="UZ">
                        Uzbekistan (UZ)
                      </option>
                      <option className="textdefault" value="VU">
                        Vanuatu (VU)
                      </option>
                      <option className="textdefault" value="VA">
                        Vatican City (VA)
                      </option>
                      <option className="textdefault" value="VE">
                        Venezuela (VE)
                      </option>
                      <option className="textdefault" value="VN">
                        Vietnam (VN)
                      </option>
                      <option className="textdefault" value="VG">
                        Virgin Islands - British (VG)
                      </option>
                      <option className="textdefault" value="VI">
                        Virgin Islands - US (VI)
                      </option>
                      <option className="textdefault" value="WF">
                        Wallis &amp; Futuna (WF)
                      </option>
                      <option className="textdefault" value="YE">
                        Yemen (YE)
                      </option>
                      <option className="textdefault" value="YE">
                        Yemen (YE)
                      </option>
                      <option className="textdefault" value="ZM">
                        Zambia (ZM)
                      </option>
                      <option className="textdefault" value="ZW">
                        Zimbabwe (ZW)
                      </option>
                    </Form.Select>
            </div>
          </div>
        </li>
        <li>
          <div className="edit-input-wrap mt-3">
            <div className="custom-input-wrapp">
            <FloatingLabel
        controlId="ncInputRegistrationCode"
        label="Registration Code"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setNewCompanyRegistrationCode(event.target.value)}
      >
        <Form.Control type="text" placeholder="name@example.com" />
        <span className="input-info-label">Eg: IN025852</span>
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Registration Code is invalid</span>
      </FloatingLabel>
            </div>
          </div>
        </li>
        <li>
          <div className="custom-input-wrapp text-area-i mt-3 mb-4">
            <textarea
              id="textAreaModifyDescription"
              className="cust-text-a"
              maxLength={4000}
              tabIndex={1}
              placeholder="Description"
              defaultValue={""}
              onChange={(e) => {setNewCompanyDescription(e.target.value)}}
            />
          </div>
        </li>
        
      </ul>
    </div>
  </li>
     
{/* REPRESENTATIVE DETAILS */}
<li>
    <div className="sec">
      <div className="head-titlw-w head-titl-unset" style={{maxWidth: '39%'}}>
        <h3
          className="sec-title"
          id="CreateCompany-Default-promptForNewCRep"
          data-info=""
        >
          Representative
        </h3>
         <OverlayTrigger  popperConfig={{
    modifiers: {
      preventOverflow: {
        enabled: false
      }
    }
  }}   placement="top"  delay={{ show: 250, hide: 400 }}
          overlay={popover("Please provide details for the person who will be accountable for managing the company's access to Nets' production systems (the 'representative')"+ 
          "The representative can use the self-service portal to delegate responsibilities.")}>
                                <a className="info-icon-w pop-common-sel sr-pophover" id="infoSelectedServiceRequestType"style={{visibility: 'visible'}} target="_blank">
                                  <span className="bi bi-info-circle mt-2" /> 
                                </a>
    </OverlayTrigger>
      </div>
      <ul className="serv-req sec-inner-wrap">
        
        <li>
          <div className="mt-3">
            <div className="custom-input-wrapp">
            <FloatingLabel
        controlId="ncInputDisplayName"
        label="Display Name"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setRepresentativeDisplayName(event.target.value)}
      >
        <Form.Control type="text" placeholder="name@example.com" />
        <span className="input-info-label">Eg: Username</span>
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Display Name is invalid</span>
      </FloatingLabel>
      <FloatingLabel
        controlId="ncInputEmail"
        label="Email Id"
        className="inputText"
        autoComplete="off"
        onChange={(event) => setRepresentativeEmailId(event.target.value)}
      >
        <Form.Control type="text" placeholder="Eg: username@domain.com" />
        <span className="input-info-label">Eg: username@domain.com</span>
        <span className="required-field-op">(Required)</span>
        <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Email Id is invalid</span>
      </FloatingLabel>
             
            </div>
          </div>
        </li>
       
        
        <li>
                    <div className="nc-rep-contact-num-wrap">
                    <div class="country-code-w">
                    {!representativeCountryCode?  <span className="countryCode-label">Select Country Code</span>:<></>}
                    <div className={`phone-input-wrapper ${flagSelected ? 'with-placeholder' : ''}`}> 
                    <PhoneInput
        country={""}
        value={representativePhoneNumber}
        enableSearch={true}
        onChange={(phone,country) => {setRepresentativePhoneNumber(phone)
                                 setRepresentativeCountryCode(country.countryCode)
                                 setRepresentativeCountry(country)
                                 setFlagSelected(true);
                                 

        }}
        placeholder="Enter phone number"
        inputProps={{
            required: true
            
        
          }}
        
      />
      </div>
      <span className="required-field-op">(Required)</span>
                    </div>
                    </div>
                    <span className="form-error error-message-show" style={{visibility: 'hidden'}}>Mobile number is invalid</span>
                  </li>
      
       
      </ul>
    </div>
  </li>

{/* ADDITIONAL DETAILS */}
<li>
    <div className="sec">
      <div className="head-titlw-w head-titl-unset" style={{maxWidth: '50%'}}>
        <h3
          className="sec-title"
          id="CreateCompany-Default-promptForAdditionalDetails"
          data-info=""
        >
          Additional Details
        </h3>
         <OverlayTrigger placement="top"  delay={{ show: 250, hide: 400 }} overlay={popover("Please provide any additional information relevant to the registration process here.")}>
                                <a className="info-icon-w pop-common-sel sr-pophover" id="infoSelectedServiceRequestType"style={{visibility: 'visible'}} target="_blank">
                                  <span className="bi bi-info-circle mt-2" /> 
                                </a>
    </OverlayTrigger>
      </div>
      <ul className="serv-req sec-inner-wrap">
        
        <li>
          <div className="custom-input-wrapp text-area-i mt-3 mb-2">
            <div className="dropdown-w-info">
              <div className="width-full">
                <textarea
                  className="cust-text-a"
                  maxLength={4000}
                  placeholder=""
                  id="textAreaModifyAdditionalDetails"
                  tabIndex={1}
                  defaultValue={""}
                  onChange= {(event) => setAdditionalDetails(event.target.value)}
                 
                />
                {/* <span className="required-field-op">(Required)</span> */}
              </div>
              
            </div>
          </div>
        </li>
      </ul>
    </div>
  </li>
  <li className="apim-pop-button-w comm-button-wrap ser-req-btn">
    <button
      className="button comm-button-style primary btn-disable"
      id="btnSubmitRegisterCompany"
      tabIndex={1}
      onClick={handleSubmit}
      disabled={!newCompanyName ?true:(!newCompanyRegistrationCode? true:(!representativeDisplayName?true:(!representativeEmailId?true:(!representativePhoneNumber?true:false))))}
    >
      Create
    </button>
    <button
      className="button comm-button-style secondary"
      id="btnLoaderRegisterCompany"
      style={{ display: "none" }}
    >
      <i className="icon-plus iconLoader" />
      Creating Service Request..
    </button>
    {/* <button
      className="button comm-button-style secondary"
      id="btnCancelRegisterCompany"
      onClick={pageReload}
      tabIndex={1}
     
    >
      Cancel
    </button> */}
     <a
            className="button comm-button-style secondary"
            href="/serviceRequest"
          >
            Cancel
          </a>
  </li> 
  <MyVerticallyCenteredModal
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
  <div className="toast-container"><ToastContainer limit={2}/></div>
  {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction} resendOtp={resendOtp}/>} 
            </>
            );
    }
    export default CreateCompany;