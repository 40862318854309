import {APP_URL,USER_CURRENT_API} from '../Config/AppConfiguration';
import axios from 'axios';
import Cookies from 'universal-cookie';
import Login from '../Components/_Authentication/Login';

class UserService{

    getAccesssToken(){ 
        const cookies = new Cookies();
        var BEARER_TOKEN = 'Bearer '.concat( cookies.get("accessToken")); 
        return BEARER_TOKEN;
    }
    getuserDetails(){
        return  axios.get(USER_CURRENT_API, { headers: {'Content-Type': 'application/json'} })
    }

    logoutUser(){
         const URL=USER_CURRENT_API.concat('/logout');
         return  axios.post(URL,{},{ headers: {'Content-Type': 'application/json','token': new Cookies().get("accessToken")} })
     }
     getUserProfileNotification(){
        const URL=USER_CURRENT_API.concat('/properties');
        return  axios.get(URL, { headers: {'Content-Type': 'application/json','token': new Cookies().get("accessToken")} })
     }

enableNotification(itemName, requestData){  
    const URL=USER_CURRENT_API.concat('/properties/').concat(itemName);
    return  axios.put(URL,requestData,{ headers: {'Content-Type': 'application/json','token': new Cookies().get("accessToken")} })
}
getStrongConfirmationCode(authURL,httpVerbData)
{
    const URL=APP_URL.concat('/getStrongConfirmationCode')
return  axios.post(URL,{authorizationUrl:authURL,httpVerb:httpVerbData},{ headers: {'Content-Type': 'application/json'} })
}

deleteUserAccount(confirmationCode){
return  axios.delete(USER_CURRENT_API+'/',{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':confirmationCode} })

}

updateMobileNumber(userDetailsObject){
    const URL=USER_CURRENT_API.concat('/newmobile')
    return axios.put(URL,userDetailsObject,{ headers: {'Content-Type': 'application/json'} });
}
activateMobileNumber(xCode){
    const URL=USER_CURRENT_API.concat('/activatenewmobile')
    return axios.post(URL,{},{ headers: {'Content-Type': 'application/json','X-StrongConfirmationCode':xCode} });           
}
changeUserDisplayName(userDetailsObject){
    const URL=USER_CURRENT_API.concat('/displayname')
    return axios.put(URL,userDetailsObject,{ headers: {'Content-Type': 'application/json'} });
}

changeUserPasswordFromProfilePage(userDetailsObject, xCode){
    const URL=USER_CURRENT_API.concat('/password')
    return axios.put(URL,userDetailsObject,{ headers: {'Content-Type': 'application/json; charset=utf-8','X-StrongConfirmationCode':xCode} });
}

async refereshUser(){
    const cookieNameCurrentUser = "currentAPIMUser";
    await this.getuserDetails().then((response) => {
      var tokenExpiry='';
      const cookies = new Cookies();
  if(cookies!==null){
    var _user_attributes = cookies.get(cookieNameCurrentUser);
  if (_user_attributes) {
    _user_attributes = decodeURI(_user_attributes);
    const user_attributes = JSON.parse(_user_attributes);
    tokenExpiry = user_attributes.accessTokenExpiry;
  }}
      const cookieNameUser = "userId";
      var _user_object = Login.setCurrentUserObject(response.data,tokenExpiry);
      _user_object = encodeURI(JSON.stringify(_user_object));
     // cookies.remove(cookieNameCurrentUser);
        cookies.set(cookieNameCurrentUser,_user_object,{path: '/'});
        cookies.set(cookieNameUser, response.data.email,{path: '/'});
      })
      .catch(error =>{
        console.log(error.status);
      });
  }

}
export default new UserService();
