import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState} from "react";
import { Button } from "react-bootstrap";
import "./InfoDeleteProfile.css";
function InfoDeleteProfile ({
  modalHeading,
  setInfoModelOpen,
  displayMessage,
  action,
  infoOkButtonAction
}) {
 
  const [showModal, setShow] = useState({ setInfoModelOpen });
  const handleClose = () => {
    setInfoModelOpen(false);
  };
  const handleShow = () => setShow(true);
 
  return (
    <div
      className="modalBackground"
      style={{ opacity: "1", width: "100vw", height: "100vh" }}
    >
      <Modal
        animation={false}
        show={showModal}
        onHide={handleClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard="False"
        dialogClassName="my-modal"
      >
        <Modal.Header className="apim-pop-header">
          <Modal.Title id="contained-modal-title-vcenter">
            {modalHeading}
          </Modal.Title>
          <div className="col-sm d-flex justify-content-end">
            <a href='javascript:;' className="pop-close-wrap mpa-button-cancel bi bi-x-lg"  onClick={() =>setInfoModelOpen(false)}/>
        </div>
         
        </Modal.Header>
        <Modal.Body>
          <div className="custom-input-wrapp has-validation">
           {displayMessage}
          </div>
        </Modal.Body>
        <Modal.Footer>
        {action === "SRQ" ? (
          
          <button className="ui button comm-button-style primary apim-pop-button-create" id="oKButton"
          onClick={() => {
            infoOkButtonAction(action);
            setInfoModelOpen(false);
          }}
           > 
         Create Service Request
          </button>
          
          ) : (
            <button className="ui button comm-button-style primary apim-pop-button-create" id="oKButton"
            onClick={() => {
              infoOkButtonAction(action);
              setInfoModelOpen(false);
            }}
           > 
         Confirm
          </button>
            
          )}

<button className="ui button comm-button-style secondary apim-pop-button-cancel deleteInfoProfileCancel" id="cancleButton"
            onClick={() => {
              setInfoModelOpen(false);
            }}
           > 
         Cancel
          </button>

         
        </Modal.Footer>
      </Modal>
    </div>
  );
}
export default InfoDeleteProfile;
