import React, { useState, useEffect,useRef } from 'react';
import {useNavigate,useLocation} from 'react-router-dom';
import { Button, Spinner,Popover} from 'react-bootstrap'
//import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import FloatingLabel from 'react-bootstrap/FloatingLabel';
import Form from 'react-bootstrap/Form';
import FormSelect from 'react-bootstrap/FormSelect';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import OtpInputModal from '../Company/OtpInputModal';
import ProjectsService from '../../Services/ProjectsService';
import './ServiceRequest.css';
import Cookies from 'universal-cookie';
import login from '../../Components/_Authentication/Login';
import { Outlet} from "react-router-dom";
import Logout from '../_Authentication/Logout';
//import {calledFromChangePassword} from '../../Config/Constant';
import { render } from '@testing-library/react';
import Metadata from '../../ServiceReqMetadata';
import CreateCompany from './CreateCompany';
import ManageCompany from './ManageCompany';
import ContactSales from './ContactSales';
import APIVersionException from './APIVersionException';
import ReportIssue from './ReportIssue';
import TechnicalAssistance from './TechnicalAssistance';
import AddCRep from './AddCRep';
import OtherEnquiries from './OtherEnquiries';
import { useParams } from "react-router-dom";





var ChangePasswordUrlTest = "https://api-gateway-pp.nets.eu/self-service/v3.4.0/users/current/password";

var ChangePasswordUrlPP= "https://api-gateway-pp.nets.eu/self-service/v3.4.0/users/current/password";
var accessTokenPP = 'Bearer '+"fff9d73e-3773-3f6d-bf6f-6a32e7fd6e10 ";

const ServiceRequest = () => {

  const { company, confirmedRepId ,requestType,requestSubType,usrExist} = useParams();
  const location = useLocation();
 
  const queryParams = new URLSearchParams(location.search);
  const restrctFld = queryParams.get('restrctFld');
  //const {companyId, cRepId ,rqType,reqSubType } = props.match.params;
   const [requestTypeDisplayName, setRequestTypeDisplayName] = useState([]);
   const [selectedRequestTypeValue, setSelectedRequestTypeValue] = useState(requestType==="ManageCompany"?
   "Manage Company Records":''
  
  );
   const [srqMainPopoverMsg, setSrqMainPopoverMsg] = useState('');
  const cookieNameToken = "accessToken";
  const [rqTypeFromURL, setRqTypeFromURL] = useState('');

  
  console.log("all url params are---"+company+confirmedRepId+requestType+requestSubType)
  // if(requestType==="ManageCompany")
  // {
  //   setRqTypeFromURL("Manage Company Records");
  // }
 

  

  const handleSelectChange = (event) => {
    let selectedValue=event.target.value;
    setSelectedRequestTypeValue(selectedValue);
    var msg = Metadata.filter(record => record.displayName===selectedValue) 
    .map((e) => {return  e.whenToUse} );
    setSrqMainPopoverMsg(msg);
  };
  const handleClearSelection = () => {
    setSelectedRequestTypeValue("");
  };
 
  const popover = (data) =>(  
  
    <Popover id="popover-basic" style={{maxWidth: '700px',height:'90px',borderColor:'black',borderStyle:'solid'}}>    
      <Popover.Body className='row'>  
        <p  className='row'> {data}</p>
      </Popover.Body>  
    </Popover>  
  ); 
  const customstyles = {
    dropdownindicator: base => ({
      ...base,
      fill: "red" // custom colour
    })
  };

useEffect(() => {
  
  var requestType = Metadata.map((e) =>  e.displayName );
  setRequestTypeDisplayName(requestType);
  console.log("ServiceReqMetadata::------->>"+Metadata.map((e) =>  e.requestType ));
  console.log("displayName::------->>"+Metadata.map((e) =>  e.displayName ));

  // if(company==="ManageCompany")
  // {
  //   setSelectedRequestTypeValue("Manage Company Records");
  // }
  
    
  },[])
//   const handleOtp = (OtpDataToProject) => {
//     setOtpDataFromModal(OtpDataToProject);
//   }
  
  
  const navigate = useNavigate();
  const navigateHome = () => {
    // 👇️ navigate to /
    navigate('/');
  };
  
 

      
     

     
     
      async function handleSubmit() {

        try {
            const response = await ProjectsService.getStrongConfirmationCode("PUT",null,"changePassword",null,null,null)
        if (response && response.data.channel == "SMS") {
           
          }
       
            
        } catch (error) {
            console.log(error);
        }
       // setModalShow(true);  //enable here for modal testing
       
      }
 
        return (
          <div
            id="sericeRequestContainer"
            className="container-fluid"
            style={{ height: "100%" }}
          >
            <div
              className="row"
              style={{ minHeight: "110px", backgroundColor: "white" }}
            >
              <div className="empty-cont-header" style={{ minHeight: "10px" }}>
                <h1 style={{color:'#2d32aa'}}>SERVICE REQUEST</h1>
              </div>
            </div>

            <div className="main-content">
              <section>
                <div className="row api-prod-row api-prod-header">
                  <div className="api-prod-col col-xs-12">
                    <section className="serv-req-cre-main">
                      <ul className="serv-req" id="serviceRequestDD">
                      <h2>CREATE SERVICE REQUEST </h2>
                        <li style={{
  opacity: (usrExist === 'false' || restrctFld === 'true') ? 0.60 : 1,
  pointerEvents: (usrExist === 'false' || restrctFld === 'true') ? 'none' : 'initial'
}}>
                          
                          
    <div className="drop-d-w">
      <div
        id="requestTypeDD"
        className="ui selection dropdown"
        tabIndex={1}
        style={{ position: 'relative' }}
      >
        <input
          type="hidden"
          name="selectSerReq"
          value={selectedRequestTypeValue || ""}
        />

        {selectedRequestTypeValue ? (
          <span 
            className="clear-icon-serviceRqst"
            onClick={handleClearSelection}
          >
            &times;
          </span>
        ) : (
          <i className="dropdown icon" />
        )}

                              <div>
                                <Form.Select
                                  defaultValue="Select Service Request"
                                  value={selectedRequestTypeValue}
                                  onChange={handleSelectChange}
                                  
                                  styles={customstyles}
                                  style={{ width: "100%" }}
                                  className={selectedRequestTypeValue ? 'dropdown-with-close' : 'dropdown-default'}
                                >
                                 
                                  <option
                                    className="textdefault"
                                    //selected
                                    value={"Select Service Request"}
                                  >
                                    {"Select Service Request"}
                                  </option>
                                  
                                  {requestTypeDisplayName.map(
                                    (option, index) => (
                                      <option key={index} value={option}>
                                        {option}
                                      </option>
                                    )
                                  )}
                                  
                                </Form.Select>
                              
                              </div>
                            </div>
                            <div className="col-sm-1">
                            {srqMainPopoverMsg != "" && (
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 250, hide: 400 }}
                                overlay={popover(srqMainPopoverMsg)}
                              >
                                <a
                                  className="info-icon-w ms-2"
                                  target="_blank"
                                >
                                  <span className="bi bi-info-circle mt-2" />
                                </a>
                              </OverlayTrigger>
                            )}
                            </div>
                          </div>
                        </li>
                      </ul>
                      <ul className="after-sel-sec serviceRequestFormBuilder">
                        {/* to render different components on selection of select options */}
                        <li>
                          {selectedRequestTypeValue === "Register Company" && (
                            <CreateCompany />
                          )}
                          {selectedRequestTypeValue ===
                            "Manage Company Records" && (
                            <ManageCompany rqValue={selectedRequestTypeValue} company={company} requestSubType={requestSubType} confirmedRepId={confirmedRepId} usrExist={usrExist} restrctFld={restrctFld}/>
                          )}
                          {selectedRequestTypeValue ===
                            "Access older API Version" && (
                            <APIVersionException />
                          )}
                          {selectedRequestTypeValue ===
                            "Request technical assistance" && (
                            <TechnicalAssistance />
                          )}
                          {selectedRequestTypeValue === "Report Issue" && (
                            <ReportIssue />
                          )}
                          {selectedRequestTypeValue === "Contact Sales" && (
                            <ContactSales />
                          )}
                          {selectedRequestTypeValue === "Other Enquiries" && (
                            <OtherEnquiries />
                          )}
                        </li>
                      </ul>
                    </section>

                    <div className="ms-clear" />
                    <div className="ms-PartSpacingVertical" />
                  </div>
                </div>
              </section>

              {/* {OtpModalOpen &&<OtpInputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={OtpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={otpInputModelOpenendForAction}/>} */}
            </div>
            <Outlet />
          </div>
        );
    
}

export default ServiceRequest;




