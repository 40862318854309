import React, { useState, useEffect } from 'react';
import Cookies from 'universal-cookie';
import {useNavigate } from 'react-router-dom';
import { STS_LOGOUT_URL,IDENTITY_LOGOUT_URL } from "../../Config/AppConfiguration";
import UserService from '../../Services/UserService';

const Logout = ({logout}) =>  {

const [isLogout, setIsLogout] = useState(false);
const [isAccesstokenNull,setIsAccesstokenNull] = useState(false);
const navigate = useNavigate();
const cookieNameUser = "userId";
const cookieNameToken = "accessToken";
const cookieNameCurrentUser = "currentAPIMUser";
const [mountedCount, setMountedCount] = useState(0);
const handleIframeLoad = async() => {
  const start = performance.now();
  
  let count=mountedCount+1;
  setMountedCount(count);
  // await sleep(3000);
  const end = performance.now();
  console.log(`  handleIframeLoad call  took: ${end - start} milliseconds`);
  //if(count==2 && isAccesstokenNull===true)
  if(count==2)
  {
    navigate('/', { replace: true });
    window.location.reload();
  }
 

}



const stsLogoutUrl = STS_LOGOUT_URL;
const identityLogoutUrl = IDENTITY_LOGOUT_URL;

const sleep = ms => new Promise(r => setTimeout(r, ms));
const handleLogout =  async()  =>{
  const cookies = new Cookies();
  if(cookies.get(cookieNameToken) !== null){
    cookies.remove(cookieNameToken);
    cookies.remove(cookieNameUser);
    cookies.remove(cookieNameCurrentUser);
  }
  if(cookies.get(cookieNameToken) === null)
  {
    setIsAccesstokenNull(true);
  }

}

const signOutHandler = async ()=> {
  const start = performance.now();
  await UserService.logoutUser().then(async(response) => {
    console.log(response.data); // Log the response to inspect it
    //alert(JSON.stringify(response.data));
    const end = performance.now();
    console.log(` UserService.logoutUser API callin signout handler with axios took: ${end - start} milliseconds`);
    handleLogout();
    setIsLogout(true);
    console.log(mountedCount + '::::isLogout mountedCount ::: '+isLogout);
  }).catch(error =>{
    console.log(error);
  });
}

   useEffect( () => {
    if(logout===true){
       signOutHandler();
    }
    },[logout]);

  return (
    <div width="560"
    height="315">
    <div style={{display : 'none'}}>
     {(!isLogout)
     ?<></>
     :<>
	 <iframe width="560"
                        height="315"
                        src={stsLogoutUrl}
                        title="stsLogout"
                        onLoad={handleIframeLoad}
                         > 
                </iframe> 
                <iframe width="560"
                        height="315"
                        src={identityLogoutUrl}
                        title="identityLogout" 
                        onLoad={handleIframeLoad}
                        > 
                </iframe> 
     </>
     }
    </div>
	 
    </div>
       );
      }

export default Logout;
