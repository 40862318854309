import { useState} from "react";
import UserService from "../../Services/UserService";
import InfoDeleteProfile from "./InfoDeleteProfile";
import OtpinputModal from "../Company/OtpInputModal";
import { useNavigate } from 'react-router-dom';
import ProjectsService from '../../Services/ProjectsService';
function DeleteAccount({userEmail})  {



  const [infoModelOpen, setInfoModelOpen] = useState(false);
  const [infoConfirmModelOpen, setInfoConfirmModelOpen] = useState(false);
//otp
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const[otpDataFromModal,setOtpDataFromModal] = useState([]);
  const[companyId,setCompanyId] = useState([]);
  const[repId,setRepId] = useState([]);
  const handleOtp = (otpDataToProject) => {
    setOtpDataFromModal(otpDataToProject);
  }

  async function verifyOtp(otpDataFromModal){
    if(otpDataFromModal.length!=0){
      console.log(otpDataFromModal)
      otpDataFromModal  = Number(otpDataFromModal.join(''));
      deleteUser(otpDataFromModal);
     }
      }
      // end otp

      const openPoup = async(id) => {
        setInfoModelOpen(true);
      setCompanyId(id);
      ProjectsService.getCompaniesCrepDetails(id).then((response) => {
        const repId = response.data.representatives.filter((e) => (e.companyId === id && e.email === userEmail))
        .map((item) => item.id);
        setRepId(repId);
      }).catch(error =>{
        console.log(error);
      });
      }
  
  const handleDelete = async () => {
    const obj = await ProjectsService.getAllProjects();
    const ee= obj.data[0].companyProjects
    .filter((e) => (e.userCanAddDelegate && e.userCanRemoveDelegate));
    console.log('length==='+ee.length);
    (0 != ee.length? openPoup(ee[0].id) : setInfoConfirmModelOpen(true))
  }
  const navigate = useNavigate();
  var requestType = 'ManageCompany';
  var requestSubType = 'ReplaceCRep';
  var usrExist = 'false';
  const infoOkButtonAction = (actionName) => {
    
    if(actionName==='DELETE')
    deleteAction();
    else if(actionName==='SRQ'){
      navigate(`/serviceRequest/${companyId}/${repId}/${requestType}/${requestSubType}/${usrExist}`);
    }
      else{
  alert('redirect to service request')

}
  }

  const deleteAction = async() => {
    const authURL = '/users'.concat('/current/');
    UserService.getStrongConfirmationCode(authURL,"DELETE").then((response) => {
      console.log("response(userService) from scc service in invokescc method:::"+response.data);
    const code= response.data.code;
      if (response && response.data.channel === 'SMS') {
        setOtpModalOpen(true);
     }else{
      deleteUser(code);
    }
     
    
    }).catch(error =>{console.log(error);})
  }

  const deleteUser = async(xCode) => {
   await UserService.deleteUserAccount(xCode).then((response) => {
    alert(response.data.message);
      console.log('delted user response::: '+response.data);
     }).catch(error =>{console.log(error);})
}

const resendOtp = async() => {
  const authURL = '/users'.concat('/current/');
    UserService.getStrongConfirmationCode(authURL,"DELETE").then((response) => {
      console.log("resendOtp for delete account");
    }).catch(error =>{console.log(error);})
}
return(
<>
          <ul className="list-unstyled">
            <li>
              <h3>Delete My Account</h3>
            </li>
            <li className="mt-3">
              <p>
                According to our{" "}
                <a
                  href="/Privacy-Notice-API-Market-Place.pdf"
                  target="_blank"
                >
                  privacy policy
                </a>
                , we may keep your data for up to 6 months.
              </p>
            </li>
            <li>
              <div className="d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="comm-button-style delete mt-3 "
                  onClick={() => handleDelete()}
                >
                  Delete my account
                </button>
                {/*-  <button type="button" class="comm-button-style delete "  disabled>
    <span class="spinner-border spinner-border-sm ms-3"></span> <span class="ms-2 mt-5">Loading...</span>
    
  </button> -*/}
              </div>
            </li>
          </ul>
          {infoModelOpen &&<InfoDeleteProfile modalHeading={"Delete My Account"} setInfoModelOpen={setInfoModelOpen} displayMessage = {"You are the confirmed representative of your companies and hence you cannot delete your account \r\n \r\n You must raise a service request so we can identify your replacement(s) before deleting your account"} action = {"SRQ"} infoOkButtonAction ={infoOkButtonAction}/>}
          {infoConfirmModelOpen &&<InfoDeleteProfile modalHeading={"Delete My Account"} setInfoModelOpen={setInfoConfirmModelOpen} displayMessage = {"Please confirm that you wish to delete your account"} action = {"DELETE"} infoOkButtonAction ={infoOkButtonAction}/>}
          {otpModalOpen &&<OtpinputModal ModalHeading={""} setOpenOtpModal={setOtpModalOpen} handleOtp={handleOtp} value={otpDataFromModal}  verifyOtp={verifyOtp} credentialRecord={""} otpInputModelOpenendForAction={""} newMobile={""} resendOtp={resendOtp}/>}
        </>
        );
}
export default DeleteAccount;